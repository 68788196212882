import {useState, useEffect, useRef} from 'react'
import Image from 'next/image'
import useDynamicFormik from '@/src/hooks/useDynamicFormik';
import { initialValues } from '@/src/datas/formData';
import { formValidationSchema } from '@/src/utils/formValidations';
import InputComponent3 from '../InputComponent3';
import PhoneCustom3 from '../PhoneCustom3';
import Head from 'next/head';
import useClientIP from '@/src/hooks/useClientIP';
import timeStampGenerator from '../lib/timeStampGenerator';
import { sendDataToSheets, sendEmail, sendEmailToCustomer } from '../lib/sendEmail';
import Link from 'next/link';

const HeroSection = ({ rawHtmlContent, mainHeroImageData, socialsBuilderObject }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  const containerStyle = {
    paddingLeft: '15px',
    paddingRight: '15px',
  };

  const rowStyle = {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
  };

  const colStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  };

  
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionBudget, setSelectedOptionBudget] = useState(null);
  const [selectProjectBegin, setSelectProjectBegin] = useState(null);
  const [selectHiringDecision, setSelectHiringDecision] = useState(null);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  
  const [value, setValue] = useState();
  const ipAddress = useClientIP();
  const ref = useRef();
const formRef = useRef();
  
  const handleTimeChange = (timeZone, timeSlot) => {
    //ask
    const timeToCall = `${timeZone} ${timeSlot?.start ? timeSlot?.start : ""} - ${timeSlot?.end ? timeSlot?.end : ""}`;
    setSelectedTime(timeToCall);
  };
  const onSubmit = async () => {
    const form_service_type =
      selectedOption?.map((option) => option.label).join(", ") ||
      "";
    const form_service_type_budget = selectedOptionBudget?.value
      ? selectedOptionBudget.value
      : "";
  
    const form_service_type_projectBegin = selectProjectBegin?.value
      ? selectProjectBegin.value
      : "";
  
    const form_service_type_hiringDecision = selectHiringDecision?.value
      ? selectHiringDecision.value
      : "not selected";
  
    try {
  
      const utcMinus11Timestamp = timeStampGenerator();
      let split_data = utcMinus11Timestamp.split(', ')
  let date_str = split_data[0]
  let time_str = split_data[1]
  
  const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
  };
  
  const formattedPhoneNumber = formatPhoneNumber(formik.values.phone);
  
  
  
      const newRow = {
        Date: date_str,
         // Time: time_str,
         State: "",
         Source: "Get Started",
         Assigned: "",
         Status: "Fresh",
         Name: formik.values.name,
         Email: formik.values.email,
         Number: formattedPhoneNumber,
         Company: "",
         "Client\nNotes": ` ${formik.values.message ? ('/') : ('')} ${formik.values.message} ${ipAddress ? ('/') : ('')} ${ipAddress}`,
         // Services: form_service_type,
         // Message: formik.values.message,
         // IP: ipAddress,
      };
      setIsFormSubmited(true);
      const gSheets = await sendDataToSheets(newRow);
      formik.setStatus({
        success: true,
        message: "Form submitted successfully",
      });
  
      if (!isVisible) {
        close(false);
      }
      // toast.success("Thank you!, Our representative will contact You.");
      setIsFormSubmited(true);
      if (!isVisible) {
        close(false);
      }
      
      const emailResponse = await sendEmail(
        formik,
        form_service_type_budget,
        form_service_type,
        form_service_type_projectBegin,
        form_service_type_hiringDecision,
        newRow,
        ipAddress,
      );
  
      const sendEmailToUser = await sendEmailToCustomer(
        formik,
        form_service_type_budget,
        form_service_type,
        form_service_type_projectBegin,
        form_service_type_hiringDecision,
        newRow,
      );
  
      if (emailResponse.data === "Success") {
        formik.setStatus({
          success: true,
          message: "Form submitted successfully",
        });
  
        if (!isVisible) {
          close(false);
        }
        // toast.success("Thank you!, Our representative will contact You.");
        setIsFormSubmited(true);
        if (!isVisible) {
          close(false);
        }
      }
      else {
        formik.setStatus({ success: false, message: "Submission error" });
  
        toast.error("Please Retry again.");
      }
   
      // }
      // else {
      //     // Handle error
      //     console.error(data.message);
      //     toast.error("Please refill captcha");
  
      // }
    } catch (error) {
      /* eslint-disable */
      console.log(error.message);
      formik.setStatus({ success: false, message: error.message });
    }
  };
  const formik = useDynamicFormik(
    initialValues,
    formValidationSchema,
    onSubmit
    
  );
  return (
    <>
    <Head>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" />
<link href="https://fonts.googleapis.com/css2?family=Lexend+Zetta:wght@100..900&display=swap" rel="stylesheet"/>

    </Head>
    <section style={{ minHeight: '100vh',  display: 'flex', alignItems: 'center', }}  className="hero-area pt-185  rel z-1">
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6 center-mobile'>
            <p className='bold-text'>
            <span>USA's Award-Winning </span> 
             <span style={{ color: '#6EBEAC' }}>   Digital Marketing Agency</span>
  
            </p>
            <p style={{ marginBottom: '0' }}>
           {/*} {mainHeroImageData.formtext} */}
           Our passion for digital Marketing Agency excellence has led us to become a leading force in USA's digital landscape. We have been honored to receive prestigious awards for our exceptional work in digital marketing services. 
           
            </p>
            <p >
            These accolades are a testament to our commitment to innovation, quality, and delivering results. As digital pioneers, we are constantly pushing the boundaries of what is possible. Join us on our journey to transform your business with advanced digital solutions USA.
            </p>
            <div style={containerStyle}>
      <div className='row' style={rowStyle}>
        <div className="col-6 col-lg-5" style={colStyle}>
          <img src="/assets/images/Vector.svg" alt="Icon" width="20" height="20" className="align-icon"/>
          <span style={{ marginLeft: '10px' }}>No Hidden Fees.</span>
        </div>
        
        <div className="col-6 col-lg-5" style={colStyle}>
          <img src="/assets/images/Vector.svg" alt="Icon" width="20" height="20" className="align-icon"/>
          <span style={{ marginLeft: '10px' }}>Fast & Optimized.</span>
        </div>
      </div>

      <div className='row' style={rowStyle}>
        <div className="col-6 col-lg-5" style={colStyle}>
          <img src="/assets/images/Vector.svg" alt="Icon" width="20" height="20" className="align-icon"/>
          <span style={{ marginLeft: '10px' }}>FREE Web Hosting.</span>
        </div>
        
        <div className="col-6 col-lg-5" style={colStyle}>
          <img src="/assets/images/Vector.svg" alt="Icon" width="20" height="20" className="align-icon"/>
          <span style={{ marginLeft: '10px' }}>Unique Web Designs.</span>
        </div>
      </div>
    </div>

            <div className='row' style={{ marginTop: '10px' }}>
              
              <div className='col-6 col-lg-4'>
              <a
                    href="/solutions/web-development-services"
                    className="footer-logo-icon-phone hover:underline"
                  >
                <button className="custom-button1" >Check The Offers</button>
                </a>
              </div>
              <div className='col-6 col-lg-4'>
              <a
                    href="tel:+12013083212"
                    className="footer-logo-icon-phone hover:underline"
                  >
                <button className="custom-button2">Schedule a Call</button>
                </a>
              </div>
            </div>
          </div>
          
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
  <div className="w-100">  {/* Add a wrapper div with full width */}
    <p className="lexendfont fs-2 text-center">
      Book a Free <span style={{ color: '#6EBEAC' }}> Consultation </span>
    </p>
    <form 
      id="homeform"
      className="homeform"
      ref={formRef}
      onSubmit={formik.handleSubmit}
      name="homeform"
      method="POST"
    >
      <div className="row" style={{ paddingTop: '15px' }}>
        <div className="col-12 col-lg-6" style={{ marginBottom: '10px' }}>
          <InputComponent3
            required={true}
            className=""
            value="name"
            placeholder="Full Name"
            formik={formik}
          />
        </div>
        <div className="col-12 col-lg-6" style={{ marginBottom: '10px' }}>
          <InputComponent3
            required={true}
            className=" border-b-[5px] border-gray-300  w-[100%]  text-gray-800 outline-none"
            value="email"
            placeholder="Email Address"
            formik={formik}
          />
        </div>
        <div className="col-12 col-lg-12">
          <div className="form-group">
            <PhoneCustom3
              formik={formik}
              className=" "
            />
          </div>
        </div>
        <div className="col-12 col-lg-12">
          <div className="form-group">
            <textarea
              name="message"
              id="message"
              className="txtfieldscontacthome"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={4}
              placeholder="Type your message here"
            />
            {formik.touched.message && formik.errors.message ? (
              <div className="error">{formik.errors.message}</div>
            ) : null}
          </div>
        </div>
        <div className="col-12 col-lg-12">
          <button className="custom-button1">Submit Form</button>
        </div>
      </div>
    </form> 
  </div>
</div>
        </div>

      </div>
  {/*}  <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div style={{  position: 'relative' }} className="hero-content">
            <h1
              dangerouslySetInnerHTML={{ __html: rawHtmlContent.html }}
            ></h1>
            {/* <Image width={300} height={300} className='globe-hero-image' src={t_hero_image_d.image} alt={t_hero_image_d.alt} /> //}
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid layout-set">
      <div className="hero-bottom-image">
      <div>
          {isMobile
           ?
           <Image style={{ 
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            display: "inline",
           }} width={1438} height={958}  priority={true} src={mainHeroImageData.mobileImage} alt={mainHeroImageData.alt} />

           :
           <Image style={{ 
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            display: "inline",
           }} width={1438} height={958}  src={mainHeroImageData.imageUrl} alt={mainHeroImageData.alt} />
          }

       </div>
        <div className="hero-social">
          <a aria-label='facebook' href={socialsBuilderObject.facebook.link}>
            <i className="fab fa-facebook-f" />{" "}
            <span>{socialsBuilderObject.facebook.platform}</span>
          </a>
          <a aria-label='twitter' href={socialsBuilderObject.twitter.link}>
            <i className="fab fa-twitter" />{" "}
            <span>{socialsBuilderObject.twitter.platform}</span>
          </a>
        </div>
      </div>
    </div>
    */}
    <div className="hero-bg">
    {isMobile
           ?
           <Image style={{ 
            width: "auto",
      height: "auto",
      maxWidth: "100%",
      display: "inline",
           }}
           width={400}
           height={300}
           priority={true}
            src="/assets/images/hero/hero-bg.png" alt="lines" />
           :
           
           <Image style={{ 
            width: "auto",
      height: "auto",
      maxWidth: "100%",
      display: "inline",
           }}
           width={1434}
           height={640}
           priority={true}
            src="/assets/images/hero/hero-bg.png" alt="lines" />

           }
      
    </div>
    
  </section>
  </>
  )
}

export default HeroSection